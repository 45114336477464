import React from "react";
import { X } from "lucide-react";
import { IoCopyOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import toast from "react-hot-toast";
import phone from "../../assets/phone_black.png";
import { capitalizeEveryWord } from "../../utils/utils";

const ContactModal = ({ isOpen, onClose, contacts }) => {
  if (!isOpen) return null;

  const handleCopy = (phone) => {
    navigator.clipboard.writeText(phone);
    toast.success("Phone number copied!", {
      duration: 2000,
      position: "top-right",
      style: {
        background: "#333",
        color: "#fff",
      },
    });
  };

  const handleWhatsApp = (phone) => {
    window.open(`https://wa.me/${phone}`, "_blank");
  };

  return (
    <div className="fixed inset-0 z-50">
      <div className="absolute inset-0 bg-black bg-opacity-50" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative bg-white rounded-lg w-full max-w-md">
            <div className="flex items-center justify-between pt-4 pb-0 px-4">
              <div className="flex items-center gap-4">
                <img src={phone} alt="Building Icon" className="w-5 h-5" />
                <span className="text-textBlack font-lato font-bold text-lg sm:text-text-22">
                  Contact Information
                </span>
              </div>
              <button
                onClick={onClose}
                className="text-white bg-btnClose rounded-md p-1"
              >
                <X size={20} className="sm:w-6 sm:h-6" />
              </button>
            </div>

            <div className="p-4">
              <p className="text-textBlack font-lato text-sm sm:text-text-14 font-medium">
                These contacts are available for property inquiries
              </p>
            </div>

            <div className="px-4 pb-6 sm:pb-8">
              <div>
                <div className="grid grid-cols-2 p-3 font-lato text-sm sm:text-text-14 font-bold text-textBlack bg-lightBlue rounded-md">
                  <div>Name</div>
                  <div>Phone number</div>
                </div>
                <div className="max-h-[300px] overflow-y-auto">
                  {contacts?.map((contact, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-2 px-3 py-2 text-sm border-gray-200"
                    >
                      <div className="text-textBlack font-lato font-medium">
                        {capitalizeEveryWord(contact.name)}
                      </div>
                      <div className="flex items-center gap-1 sm:gap-2 text-textBlack font-lato font-medium">
                        {/* For mobile: clickable phone number */}
                        <a
                          href={`tel: ${contact.phone_number}`}
                          className="flex items-center gap-1 sm:hidden"
                        >
                          <span className="text-textBlack text-sm">
                            {contact.phone_number}
                          </span>
                        </a>

                        {/* For desktop: phone number with copy button */}
                        <div className="hidden sm:flex items-center gap-2">
                          <span className="text-textBlack text-sm">
                            {contact.phone_number}
                          </span>
                          <button
                            onClick={() => handleCopy(contact.phone_number)}
                            className="p-1 hover:bg-gray-100 rounded"
                            title="Copy number"
                          >
                            <IoCopyOutline size={16} className="text-gray-500" />
                          </button>
                        </div>

                        {/* WhatsApp button for both mobile and desktop */}
                        <button
                          onClick={() => handleWhatsApp(contact.phone_number)}
                          className="p-1 hover:bg-gray-100 rounded"
                          title="Open WhatsApp"
                        >
                          <FaWhatsapp size={16} className="text-gray-500" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;