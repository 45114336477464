import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propertyService } from "../../services/propertyService";

export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async ({ clientId }, { rejectWithValue }) => {
    try {
      const response = await propertyService.fetchProperties(clientId);

      // Validate response structure
      if (!response.data || !Array.isArray(response.data.properties)) {
        throw new Error("Invalid response format");
      }

      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch properties"
      );
    }
  }
);

const initialState = {
  allProperties: [],
  filteredProperties: [],
  activeTab: "all",
  loading: false,
  initialLoad: true,
  error: null,
};

export const propertySlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      if (action.payload === "all") {
        state.filteredProperties = state.allProperties;
      } else {
        state.filteredProperties = state.allProperties.filter(
          (property) => property.tower === action.payload
        );
      }
    },
    setInitialLoadComplete: (state) => {
      state.initialLoad = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.loading = false;
        state.allProperties = action.payload.data.properties;
        state.filteredProperties = action.payload.data.properties;
        state.error = null;
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch properties";
        state.allProperties = [];
        state.filteredProperties = [];
      });
  },
});

export const { setActiveTab, setInitialLoadComplete } = propertySlice.actions;
export default propertySlice.reducer;
