export function capitalizeFirstLetter(input) {
  if (typeof input !== "string") {
    return "N/A";
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function capitalizeFirstLetterOnly(string) {
  const capitalized = capitalizeFirstLetter(string);
  return capitalized.charAt(0) + capitalized.slice(1).toLowerCase();
}

export const capitalizeEveryWord = (string) => {
  if (!string) return "";

  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
