import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_PUBLIC_URL;

const getStoredClientCount = () => {
  const count = localStorage.getItem('lastClientCount');
  return count ? parseInt(count) : 1;
};

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/building/list-clients`, {
        headers: {
          Authorization: ``,
        },
      });
      // Transform the data to flatten the client structure
      const transformedClients = response.data.data.clients.map(item => item.client);
      // Store the client count when we get successful response
      localStorage.setItem('lastClientCount', transformedClients.length);
      return {
        ...response.data,
        data: {
          ...response.data.data,
          clients: transformedClients
        }
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch clients");
    }
  }
);

const initialState = {
  clients: [],
  selectedClient: null,
  loading: false,
  error: null,
  showProperties: false,
  clientsLoading: false,
  lastKnownCount: getStoredClientCount(),
};

const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
      state.showProperties = true;
    },
    resetView: (state) => {
      state.showProperties = false;
      state.selectedClient = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.loading = false;
        state.clients = action.payload.data.clients;
        state.lastKnownCount = action.payload.data.clients.length;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch clients";
      });
  },
});

export const { setSelectedClient, resetView } = clientSlice.actions;
export default clientSlice.reducer;