import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/logo.png";
import defaultUser from "../../assets/defaultUser.png";

const Header = () => {
  const { selectedClient } = useSelector((state) => state.clients);
  const [buildingImage, setBuildingImage] = useState(defaultUser);

  useEffect(() => {
    if (selectedClient?.profile_picture_url) {
      setBuildingImage(selectedClient.profile_picture_url);
    } else {
      setBuildingImage(defaultUser);
    }
  }, [selectedClient]);

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          {/* Logo and Title */}
          <div className="flex items-center">
            <img
              src={logo}
              alt="JOY Logo"
              className="h-8 w-8 sm:h-10 sm:w-10"
            />
            <h1 className="ml-3 sm:ml-4 text-lg sm:text-custom-22 font-lato font-bold text-gray-900 tracking-[0.1px]">
              Joy App
            </h1>
          </div>

          {/* Dynamic Building Icon */}
          <div className="bg-white rounded-full flex items-center lg:hidden">
            <img
              src={buildingImage}
              alt="Building Icon"
              className="w-10 h-10 object-cover rounded-full"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultUser;
              }}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
