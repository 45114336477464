import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClients,
  setSelectedClient,
} from "../../../redux/slices/clientSlice";
import defaultUser from "../../../assets/defaultUser.png";
import { useNavigate } from "react-router-dom";

const ClientCard = ({ client, onViewProperties }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 flex flex-col md:flex-row items-center justify-between gap-4 mb-4">
    <div className="flex flex-col sm:flex-row items-center gap-4">
      <img
        src={client.profile_picture_url || defaultUser}
        alt={client.name}
        className="w-24 h-24 rounded-full object-cover"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/api/placeholder/64/64";
        }}
      />
      <div className="flex flex-col justify-center text-center sm:text-left">
        <h3 className="text-xl font-bold text-gray-900">{client.name}</h3>
        <p className="text-gray-600">{client.address}</p>
      </div>
    </div>
    <button
      onClick={() => onViewProperties(client)}
      className="w-full md:w-auto bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors"
    >
      View Properties
    </button>
  </div>
);

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center py-12 px-4">
    <div className="text-center">
      <h3 className="mt-2 text-xl font-medium text-gray-900">No Properties Found</h3>
      <p className="mt-1 text-sm text-gray-500">
        There are currently no properties available to display.
      </p>
    </div>
  </div>
);

const ClientList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clients, loading, error, lastKnownCount } = useSelector(
    (state) => state.clients
  );

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  const handleViewProperties = (client) => {
    if (!client || !client._id) {
      console.error("Invalid client data:", client);
      return;
    }

    dispatch(setSelectedClient(client));
    navigate(`/properties/${client._id}`);
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="space-y-4">
          {Array(lastKnownCount)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                className="animate-pulse bg-white rounded-lg shadow-sm p-6"
              >
                <div className="flex flex-col sm:flex-row items-center gap-4">
                  <div className="w-24 h-24 bg-gray-200 rounded-full" />
                  <div className="space-y-2 text-center sm:text-left">
                    <div className="h-4 bg-gray-200 rounded w-48" />
                    <div className="h-4 bg-gray-200 rounded w-32" />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-red-50 border border-red-200 rounded-md p-4">
          <p className="text-red-600">Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      {clients.length === 0 ? (
        <EmptyState />
      ) : (
        <div className="space-y-4">
          {clients.map((client) => (
            <ClientCard
              key={client._id}
              client={client}
              onViewProperties={handleViewProperties}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ClientList;