import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedClient } from "../../../redux/slices/clientSlice";
import {
  fetchProperties,
  setActiveTab,
} from "../../../redux/slices/propertySlice";
import BuildingInfo from "./BuildingInfo";
import TowerTabs from "./TowerTabs";
import PropertyTable from "./PropertyTable";
import { Toaster } from "react-hot-toast";

const PropertyDashboard = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clients, clientsLoading } = useSelector((state) => state.clients);

  const {
    loading: propertiesLoading,
    allProperties,
    filteredProperties,
    activeTab,
    error,
  } = useSelector((state) => state.properties);

  useEffect(() => {
    // Only proceed if clients are loaded
    if (!clientsLoading && clients.length > 0) {
      const client = clients.find((c) => c._id === clientId);

      if (!client) {
        navigate("/");
        return;
      }

      dispatch(setSelectedClient(client));
      dispatch(fetchProperties({ clientId: client._id }));
      dispatch(setActiveTab("all"));
    }
  }, [clientId, clients, clientsLoading, dispatch, navigate]);

  // Show loading state while clients are being fetched
  if (clientsLoading || propertiesLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div>Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <BuildingInfo />
        <div>Error: {error}</div>
      </div>
    );
  }

  const hasProperties =
    Array.isArray(allProperties) && allProperties.length > 0;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <BuildingInfo />

      {hasProperties ? (
        <>
          <TowerTabs properties={allProperties} activeTab={activeTab} />
          <PropertyTable
            properties={filteredProperties}
            loading={propertiesLoading}
          />
          <Toaster />
        </>
      ) : (
        <div className="text-center py-4">
          No properties available at this moment
        </div>
      )}
    </div>
  );
};

export default PropertyDashboard;
