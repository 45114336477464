import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ChevronDown, X } from "lucide-react";
import info from "../../../assets/info.png";
import defaultUser from "../../../assets/defaultUser.png";
import { propertyService } from "../../../services/propertyService";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
      <div className="relative bg-white rounded-lg max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto z-50">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
};

const ContentBlock = ({ content, isDesktop = false, onReadMore }) => {
  const contentRef = useRef(null);
  const [shouldShowReadMore, setShouldShowReadMore] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const isOverflowing = contentHeight > 320;
        setShouldShowReadMore(isOverflowing);
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);
    return () => window.removeEventListener("resize", checkHeight);
  }, [content]);

  return (
    <div className={`relative ${!isDesktop ? "lg:hidden" : "hidden lg:block"}`}>
      <div className="flex">
        {content.length > 0 && (
          <div className="flex-shrink-0 mr-2">
            <img src={info} alt="Info Icon" className="h-5 w-5" />
          </div>
        )}
        <div
          ref={contentRef}
          className={`text-text-14 font-roboto text-textBlack flex-grow
            [&>p:first-child]:ml-0
            [&>p]:mb-2
            [&>h2]:font-bold
            [&>h2]:text-lg
            [&>h2]:mt-3
            [&>h2]:mb-2
            [&_strong]:font-bold
            [&_strong]:text-textBlack
            [&>ul]:ml-7
            [&_br]:hidden
            ${isDesktop ? "max-h-[300px] overflow-hidden" : ""}`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {shouldShowReadMore && isDesktop && (
        <div className="ml-7 mt-2">
          <button
            onClick={onReadMore}
            className="text-textBlack font-roboto text-text-14 underline decoration-solid"
          >
            Read more
          </button>
        </div>
      )}
    </div>
  );
};

const MobileContent = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);
  const [shouldShowReadMore, setShouldShowReadMore] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const isOverflowing = contentHeight > 192;
        setShouldShowReadMore(isOverflowing);
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);
    return () => window.removeEventListener("resize", checkHeight);
  }, [content]);

  return (
    <div className="lg:hidden">
      <div className="flex">
        {content.length > 0 && (
          <div className="flex-shrink-0 mr-2">
            <img src={info} alt="Info Icon" className="h-5 w-5" />
          </div>
        )}
        <div
          ref={contentRef}
          className={`space-y-1.5 flex-grow ${
            !isExpanded ? "max-h-48 overflow-hidden" : ""
          }`}
        >
          <div
            className="text-text-14 font-roboto text-textBlack
              [&>p:first-child]:ml-0
              [&>p]:mb-2
              [&>h2]:font-bold
              [&>h2]:text-lg
              [&>h2]:mt-3
              [&>h2]:mb-2
              [&_strong]:font-bold
              [&_strong]:text-textBlack
              [&>ul]:ml-7
              [&_br]:hidden"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
      {shouldShowReadMore && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="ml-7 mt-2 flex items-center text-textBlack font-roboto text-text-14 underline decoration-solid"
        >
          {isExpanded ? "Show less" : "Read more"}
          <ChevronDown
            className={`ml-1 w-4 h-4 transition-transform duration-200 ${
              isExpanded ? "rotate-180" : ""
            }`}
          />
        </button>
      )}
    </div>
  );
};

const BuildingInfo = () => {
  const [bannerInfo, setBannerInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedClient } = useSelector((state) => state.clients);

  const cleanHtmlContent = (html) => {
    return html
      .replace(/<p><br><\/p>/g, "")
      .replace(/<p><br\/><\/p>/g, "")
      .replace(/<p><br \/><\/p>/g, "")
      .replace(/\s*<\/p>\s*<p>\s*/g, "</p><p>");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedClient?._id) return;

      try {
        const response = await propertyService.fetchProperties(
          selectedClient._id
        );
        if (response.success && response.data.client) {
          const cleanedBannerInfo = cleanHtmlContent(
            response.data.client.info_banner
          );
          setBannerInfo(cleanedBannerInfo);
        }
      } catch (error) {
        console.error("Error fetching banner info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedClient]);

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6">
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6">
      <div className="flex flex-col lg:flex-row lg:justify-between">
        {/* Logo and Title Section */}
        <div className="hidden lg:flex lg:flex-1 items-center space-x-4">
          <div className="bg-white rounded-full">
            <img
              src={selectedClient?.profile_picture_url || defaultUser}
              alt="Company Logo"
              className="w-40 h-40 rounded-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/api/placeholder/56/56";
              }}
            />
          </div>
          <h2 className="font-lato font-bold text-custom-62 text-textBlack">
            {selectedClient?.name}
          </h2>
        </div>

        {/* Vertical Divider - Desktop Only */}
        <div className="hidden lg:block w-px mx-8 xl:mx-20 my-4 bg-grey self-stretch" />

        {/* Content Section */}
        <div className="lg:flex-1">
          <div className="space-y-2">
            {/* Desktop Content */}
            <ContentBlock
              content={bannerInfo}
              isDesktop={true}
              onReadMore={() => setIsModalOpen(true)}
            />

            {/* Mobile Content */}
            <MobileContent content={bannerInfo} />
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div
          className="text-text-14 font-roboto text-textBlack
            [&>p]:ml-7
            [&>p]:mb-2
            [&>h2]:font-bold
            [&>h2]:text-lg
            [&>h2]:mt-3
            [&>h2]:mb-2
            [&_strong]:font-bold
            [&_strong]:text-textBlack
            [&>ul]:ml-7
            [&_br]:hidden"
          dangerouslySetInnerHTML={{ __html: bannerInfo }}
        />
      </Modal>
    </div>
  );
};

export default BuildingInfo;
