import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { HomeIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "lucide-react";
import clsx from "clsx";
import { setActiveTab } from "../../../redux/slices/propertySlice";

const TowerTabs = ({ properties, activeTab }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Get unique towers from properties
  const towers = useMemo(() => {
    if (!properties?.length) return [];
    const uniqueTowers = [...new Set(properties.map((prop) => prop.tower))];
    return uniqueTowers;
  }, [properties]);

  const handleTabChange = (tower) => {
    dispatch(setActiveTab(tower));
    setIsDropdownOpen(false);
  };

  if (!properties?.length) {
    return null;
  }

  return (
    <div className="mb-6">
      {/* Mobile View */}
      <div className="lg:hidden px-4">
        <div className="text-text-14 font-medium text-gray-500 mb-2">Show:</div>
        <div className="relative">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="w-full px-4 py-2 bg-white border border-gray-300 rounded-lg flex justify-between items-center"
          >
            <span className="text-text-14 font-medium">
              {activeTab === "all" ? "All Towers" : activeTab}
            </span>
            <ChevronDownIcon className="h-5 w-5" />
          </button>

          {isDropdownOpen && towers.length > 0 && (
            <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
              <button
                onClick={() => handleTabChange("all")}
                className="w-full px-4 py-2 text-left text-text-14 hover:bg-gray-100"
              >
                All Towers
              </button>
              {towers.map((tower) => (
                <button
                  key={tower}
                  onClick={() => handleTabChange(tower)}
                  className="w-full px-4 py-2 text-left text-text-14 hover:bg-gray-100"
                >
                  {tower}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block border-b border-gray-200">
        <div className="overflow-x-auto scrollbar-hide">
          <nav className="-mb-px flex whitespace-nowrap" aria-label="Tabs">
            <button
              onClick={() => handleTabChange("all")}
              className={clsx(
                "group inline-flex items-center py-3 px-4 border-b-2 font-bold text-sm sm:text-base font-lato shrink-0",
                activeTab === "all"
                  ? "border-textBlue text-textBlue"
                  : "border-transparent text-gray-500 hover:text-black hover:border-textBlack"
              )}
            >
              <HomeIcon className="mr-2 h-4 w-4" />
              All Towers
            </button>

            {towers.map((tower) => (
              <button
                key={tower}
                onClick={() => handleTabChange(tower)}
                className={clsx(
                  "py-3 px-4 border-b-2 font-medium text-sm sm:text-base shrink-0",
                  activeTab === tower
                    ? "border-textBlue text-textBlue"
                    : "border-transparent text-gray-500 hover:text-black hover:border-textBlack"
                )}
              >
                {tower}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TowerTabs;
