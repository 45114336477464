import React, { useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import Badge from "../components/common/Badge";
import phone from "../assets/phone.png";

export const usePropertyTable = (data) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "tower",
        header: "Tower",
        size: 80,
        cell: ({ getValue }) => getValue() || "N/A",
      },
      {
        accessorKey: "id",
        header: "Property ID",
        size: 120,
        cell: ({ getValue }) => getValue() || "N/A",
      },
      {
        accessorKey: "beds",
        header: "No. Of Bed",
        size: 120,
        cell: ({ getValue }) => getValue() || "N/A",
      },
      {
        accessorKey: "rent",
        header: "Base Rent (QAR)",
        size: 120,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? `${value.toLocaleString()}` : "N/A";
        },
      },
      {
        accessorKey: "prorated_price",
        header: "Pro Rated Price (QAR)",
        size: 120,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? `${value.toLocaleString()}` : "N/A";
        },
      },
      {
        accessorKey: "location",
        header: "View",
        size: 150,
        cell: ({ getValue }) => getValue() || "N/A",
      },
      {
        accessorKey: "type",
        header: "Property Type",
        size: 120,
        cell: ({ getValue }) => getValue() || "N/A",
      },
      {
        accessorKey: "size",
        header: "BUA (SQM)",
        size: 110,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? `${value.toLocaleString()}` : "N/A";
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 110,
        cell: ({ row }) => {
          // const status = row.original.status || 'Available';
          return <Badge status={"Available"} />;
        },
      },
      {
        accessorKey: "agent_contact",
        header: "Contact",
        size: 120,
        cell: ({ row }) => {
          return (
            <button
              onClick={() => {
                setSelectedContacts(row.original.management_contacts || []);
                setIsModalOpen(true);
              }}
              className="whitespace-nowrap py-2 px-4 bg-white border border-textBlue rounded-lg text-text-14 text-textBlue flex items-center justify-center gap-x-2"
            >
              <img src={phone} alt="Building Icon" className="w-5 h-5" />
              View Contact
            </button>
          );
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return {
    table,
    isModalOpen,
    setIsModalOpen,
    selectedContacts,
  };
};
