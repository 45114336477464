import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import Header from "./components/layout/Header";
import ClientList from "./components/features/properties/ClientList";
import PropertyDashboard from "./components/features/properties/PropertyDashboard";
import CircularLoader from "./components/common/CircularLoader";
import { fetchClients } from "./redux/slices/clientSlice";

const App = () => { 
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.properties);
  const { clientsLoading } = useSelector((state) => state.clients);

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <BrowserRouter>
      <div className="min-h-screen bg-backgroundGrey">
        {(loading || clientsLoading) && <CircularLoader />}
        <Header />
        <Routes>
          <Route path="/" element={<ClientList />} />
          <Route 
            path="/properties/:clientId" 
            element={
              <PropertyDashboard />
            } 
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;