import axios from "axios";

const BASE_URL = process.env.REACT_APP_PUBLIC_URL;

export const propertyService = {
  fetchProperties: async (clientId, tower = "") => {
    try {
      const params = new URLSearchParams();
      if (tower) params.append("tower", tower);
      if (clientId) params.append("client_id", clientId);

      const queryString = params.toString();
      const url = `${BASE_URL}/properties/listing${
        queryString ? `?${queryString}` : ""
      }`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Make sure token is being sent
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching properties:", error.response || error);
      throw error;
    }
  },
};
