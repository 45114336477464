import React from "react";
import { useSelector } from "react-redux";
import { flexRender } from "@tanstack/react-table";
import { usePropertyTable } from "../../../hooks/usePropertyTable";
import TowerCard from "./TowerCard";
import { Button } from "@headlessui/react";
import ContactModal from "../../common/ContactModal";

const PropertyTable = () => {
  // Get filtered properties from Redux store
  const { filteredProperties, allProperties, loading } = useSelector(
    (state) => state.properties
  );
  
  // Use the appropriate properties array based on which tab is active
  const { activeTab } = useSelector((state) => state.properties);
  const displayProperties = activeTab === "all" ? allProperties : filteredProperties;
  const { table, isModalOpen, setIsModalOpen, selectedContacts } = usePropertyTable(displayProperties);

  // Handle loading state 
  if (loading) {
    return <div className="text-center py-10 text-gray-500">Loading...</div>;
  }

  if (!allProperties?.length) {
    return <div className="text-center py-10 text-gray-500">No properties available</div>;
  }

  if (!displayProperties?.length) {
    return <div className="text-center py-10 text-gray-500">No properties found for the selected tower</div>;
  }

  return (
    <>
      {/* Mobile View - Tower Cards */}
      <div className="lg:hidden space-y-4">
        {displayProperties?.map((property) => (
          <TowerCard
            key={property?._id}
            tower={property.tower}
            propertyId={property.id}
            noOfBed={property.beds}
            baseRent={property.rent}
            proRatedPrice={property.prorated_price}
            view={property.location}
            propertyType={property.type}
            bua={property.size}
            status={property.status}
            contact={property.agent_contact}
            managementContacts={property.management_contacts}
          />
        ))}
      </div>

      <div className="hidden lg:block overflow-x-auto">
        <div className="w-full bg-white rounded-lg shadow-sm">
          <table className="min-w-full w-full table-auto">
            <thead className="bg-gray-50">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="px-4 py-3 text-left text-text-14 font-bold font-lato text-textBlack tracking-wider bg-lightBlue"
                      style={{
                        width: header.getSize() ? `${header.getSize()}px` : "auto",
                        minWidth: header.getSize() ? `${header.getSize()}px` : "auto",
                      }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="border-b">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-4 py-4 text-text-14 font-normal font-lato text-textBlack"
                      style={{
                        width: cell.column.getSize() ? `${cell.column.getSize()}px` : "auto",
                        minWidth: cell.column.getSize() ? `${cell.column.getSize()}px` : "auto",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="px-6 py-3 flex items-center justify-between border-t border-gray-200">
            <div className="flex gap-2">
              <Button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                variant="secondary"
              >
                Previous
              </Button>
              <Button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                variant="secondary"
              >
                Next
              </Button>
            </div>
            <span className="text-sm text-gray-700">
              Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </span>
          </div>
        </div>

        <ContactModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          contacts={selectedContacts}
        />
      </div>
    </>
  );
};

export default PropertyTable;